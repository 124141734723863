import { Container, FlexDiv, Image, RightSide } from "./About.styles";
import { Typography } from "@mui/material";
import AboutImage from "../../assets/me.jpeg";

const About = () => {
	return (
		<Container>
			<FlexDiv>
				<div>
					<Typography variant='h4'>About Abe</Typography>
					<Image
						src={AboutImage}
						alt='Abe and Grace'
					/>
				</div>
				<RightSide>
					<Typography variant='h5'>I’m a front end engineer.</Typography>
					<Typography variant='body1'>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas sequi
						modi vel dolor et, natus officiis nisi. Adipisci porro, blanditiis
						iste culpa rerum minima ab obcaecati tempora distinctio! Sint,
						magni.
					</Typography>
				</RightSide>
			</FlexDiv>
		</Container>
	);
};

export default About;
