import { useRouteError } from "react-router-dom";

import { Typography } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import { Container } from "./NotFound.styles";

import NotFoundImage from "../../assets/404.jpeg";

const NotFound = () => {
	const error = useRouteError();

	return (
		<Navbar>
			<Container>
				<img
					src={NotFoundImage}
					alt='not_found'
				/>
				<Typography variant='h5'>
					{error.statusText || error.message}
				</Typography>
			</Container>
		</Navbar>
	);
};

export default NotFound;
