import Navbar from "./Navbar/Navbar";
import { StyledOutlet } from "./App.styles";

const App = () => {
	return (
		<Navbar>
			<StyledOutlet />
		</Navbar>
	);
};

export default App;
