import { useEffect, useState, useRef } from "react";

import { FormControlLabel, Switch } from "@mui/material";
import bellSound from "../../assets/sounds/bell.mp3";

const DELAY = 1000 * 1; // 1 second

const Bell = () => {
	const getCurrentHour = () => new Date().getHours();
	const dependecy = getCurrentHour();
	const [hour, setHour] = useState(getCurrentHour());
	const [isBellOn, setIsBellOn] = useState(false);
	const audioRef = useRef();

	const handleChange = () => {
		setHour(getCurrentHour());
		setIsBellOn(!isBellOn);
		pauseAudio();
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (hour === getCurrentHour()) {
			}
			if (getCurrentHour() > hour && isBellOn) {
				setHour(getCurrentHour());
				playAudio();
			}
		}, DELAY);

		return () => clearInterval(interval);
	}, [hour, dependecy, isBellOn]);

	const playAudio = () => {
		audioRef.current.play();
	};

	const pauseAudio = () => {
		audioRef.current.pause();
	};

	return (
		<>
			<audio
				ref={audioRef}
				src={bellSound}
			/>
			<FormControlLabel
				label='Bell'
				control={
					<Switch
						checked={isBellOn}
						onChange={handleChange}
					/>
				}
			/>
		</>
	);
};

export default Bell;
