import { createBrowserRouter } from "react-router-dom";

// Routes
import App from "../components/App";
import Home from "../components/Home/Home";
import About from "../components/About/About";
import NotFound from "../components/NotFound/NotFound";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <NotFound />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/about",
				element: <About />,
			},
		],
	},
]);

export default router;
