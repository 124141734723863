import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// Components
import Bell from "../Bell/Bell";
import { Button, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Styles
import {
	Container,
	Title,
	StyledDrawer,
	TopMenuItems,
	LeftSideContainer,
} from "./Navbar.styles";

const Navbar = ({ children }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenuClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<>
			<Paper>
				<Container>
					<LeftSideContainer>
						<Button
							variant='filled'
							style={{ textTransform: "none" }}
							onClick={handleMenuClick}
							startIcon={<MenuIcon />}>
							Menu
						</Button>
						<Title>Abe Garabedian</Title>
					</LeftSideContainer>
					<Bell />
				</Container>
				<StyledDrawer
					open={isMenuOpen}
					anchor='left'
					onClose={handleMenuClick}>
					<TopMenuItems>
						<Button
							variant='filled'
							to={"/"}
							component={RouterLink}
							onClick={handleMenuClick}>
							Home
						</Button>
						<Button
							variant='filled'
							to={"/about"}
							component={RouterLink}
							onClick={handleMenuClick}>
							About Me
						</Button>
					</TopMenuItems>
				</StyledDrawer>
			</Paper>
			{children}
		</>
	);
};

export default Navbar;
