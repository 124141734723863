import { Container } from "./Home.styles";

const Home = () => {
	return (
		<Container>
			<p>Home</p>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
				minus quidem, dolorem ipsum voluptatum quod nesciunt ex mollitia magnam
				quam provident dolorum vel eligendi placeat velit assumenda, adipisci
				maxime omnis.
			</p>
		</Container>
	);
};

export default Home;
