import styled from "styled-components";
import { Typography, Drawer } from "@mui/material";

export const Container = styled.div`
	height: 80px;
	display: flex;
	gap: 16px;
	background-color: #9ba4b5;
	align-items: center;
	justify-content: space-between;
`;

export const Title = styled(Typography).attrs({
	variant: "h5",
})`
	margin-left: 24px;
`;

export const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paperAnchorLeft {
		width: 150px;
		padding-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const TopMenuItems = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LeftSideContainer = styled.div`
	display: flex;
`;
