import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const Container = styled(Paper)`
	margin: 8px;
	padding: 16px;
`;

export const FlexDiv = styled.div`
	display: flex;
	/* flex-direction: column; */
	/* align-items: center; */
`;

export const Image = styled.img`
	border-radius: 8px;
	max-width: fit-content;
	margin: 16px 8px;
`;

export const RightSide = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	margin: 16px;
`;
