import { createTheme } from "@mui/material";

const theme = createTheme({
	palette: {
		background: {
			// default: "#212a3e", dark
			default: "#9ba4b5",
			paper: "#9ba4b5",
		},
	},
});

export default theme;
